
export const AppMenus = {
    
	navbarTopRightItems: [],
	navbarTopLeftItems: [],
	navbarSideLeftItems: [
  {
    "to": "/home",
    "label": "Inicio",
    "icon": "pi pi-home text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/data",
    "label": "FTP Log",
    "icon": "pi pi-server text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/devices",
    "label": "Dispositivos",
    "icon": "pi pi-box text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Configuación",
    "icon": "pi pi-cog text-primary",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/clients",
        "label": "Clients",
        "icon": "pi pi-briefcase",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/users",
        "label": "Users",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/users_type",
        "label": "Users Type",
        "icon": "pi pi-clone",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: '',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}